<template>
  <div>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
    <div class="dialog-footer">
      <el-button type="primary" @click="headCancel">取 消</el-button>
    </div>
  </div>
</template>
<script>

import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {getDetail} from "@/api/reportDistribution";


export default {
  name: "apiEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  props: {
    formData: {
      type: Object,
    }
  },
  data() {
    return {
      dataForm: {},
    }
  },
  computed: {
    formColumn() {
      return [
        {
          label: "报表名称",
          prop: 'templateName',
          labelWidth: 130,
          span: 12,
          disabled:true,
        },
        {
          label: "业务分类",
          prop: 'reportCategoryCode',
          labelWidth: 130,
          type: "select",
          span: 12,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=report_category",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          disabled:true,
        },
        {
          label: "下发时间",
          prop: 'issuingTime',
          labelWidth: 130,
          span: 12,
          type: "date",
          format: 'yyyy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          disabled:true,
        },
        {
          label: "是否成功下发",
          prop: "isEnable",
          align: 'center',
          type: "select",
          dicUrl: "/api/sinoma-system/dict/dictionary?code=yes_no",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          span: 12,
          labelWidth: 130,
          disabled:true,
        },
        {
          label: "下发总数",
          prop: 'issuedNumber',
          labelWidth: 130,
          span: 12,
          disabled:true,
        },
        {
          label: "完成数",
          prop: 'completedNumber',
          labelWidth: 130,
          span: 12,
          disabled:true,
        },
        {
          label: "未完成数",
          prop: 'unfinishedNumber',
          labelWidth: 130,
          span: 12,
          disabled:true,
        },
        {
          label: "补发次数",
          prop: 'reissuesNumber',
          labelWidth: 130,
          span: 12,
          disabled:true,
        },
        {
          label: "补发批次数",
          prop: 'reissuesBatch',
          labelWidth: 130,
          span: 12,
          disabled:true,
        },
        {
          label: "补发总数",
          prop: 'reissuesNumberTotal',
          labelWidth: 130,
          span: 12,
          disabled:true,
        },

      ]
    },
  },
  created() {
    if(this.formData.row){
      let row = JSON.parse(this.formData.row);
      if(row.id){
        this.dataForm.id = row.id
        this.initData()
      }
    }
  },
  mounted() {
  },
  methods: {
    initData() {
      getDetail(this.dataForm.id).then(res => {
        res.data.data.isEnable = res.data.data.isEnable == '1' ? '1' : '0';
        const { data } = res.data;
        this.dataForm = data;
      })
    },
    headCancel() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: right;
}
</style>
